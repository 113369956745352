<template>
  <div class="flowDetails">
    <Header title="流水明细" />
    <div class="main overflow-y-auto">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="detailItem flex-center-between" v-for="item in list" :key="item.id">
          <div class="detailInfo">
            <div class="detailTitle">{{ item.desc }}</div>
            <div class="timer">{{ item.createdAt | time }}</div>
          </div>
          <div class="goldBox">
            {{ item.money !== '0' ? item.money : item.amount }}
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>
<script>
import { billDetail } from '@/api/mine';
import Header from '@/components/Header';
import PullRefresh from '@/components/PullRefresh';

export default {
  name: 'flowDetails',
  components: {
    Header,
    PullRefresh,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(billDetail, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
  },
};
</script>
<style lang="scss" scoped>
.flowDetails {
  height: 100%;
  .main {
    height: calc(100vh - 54px);
    background-color: rgb(248, 248, 248);
    padding-top: 10px;
    .detailItem {
      padding: 20px 14px 12px;
      box-sizing: border-box;
      border-bottom: 1px solid rgb(244, 244, 244);
      background-color: white;
      .detailInfo {
        .detailTitle {
          font-size: 16px;
          color: rgb(51, 51, 51);
          margin-bottom: 4px;
        }
        .timer {
          font-size: 12px;
          color: rgb(153, 153, 153);
        }
      }
      .goldBox {
        font-size: 24px;
        color: rgb(255, 75, 133);
      }
    }
  }
}
</style>
